const SERVER_DOMAIN_PROD = "https://api.webdrasill.com";
const SERVER_DOMAIN_DEV = "https://api.webdrasill.com";

let CONTAINER_SERVER_DOMAIN = "";
if (process.env.REACT_APP_MODE === "dev") {
	CONTAINER_SERVER_DOMAIN = SERVER_DOMAIN_DEV;
}
if (process.env.REACT_APP_MODE === "prod") {
	CONTAINER_SERVER_DOMAIN = SERVER_DOMAIN_PROD;
}

export const SERVER_DOMAIN = CONTAINER_SERVER_DOMAIN;
export const STATE_GUEST_PASSWORD: boolean = true;